    import React, { useState ,useEffect} from 'react';
    import FriendInfo from './FriendInfo';
    import Message from './Message';
    import MessageSend from './MessageSend';
    import Vacall from './Vacall';
    import CallNotification from './CallNotification';
    import toast from 'react-hot-toast';
    import moment from 'moment';
    import { useDispatch } from 'react-redux';
    const RightSide = (props) => {
        const [searchTerm, setSearchTerm] = useState('');
        const [showFriendInfo, setShowFriendInfo] = useState(false); // State to control visibility of FriendInfo
        const [caller, setCaller] = useState(false); // State to control visibility of Call
        const dispatch = useDispatch();
        const { friends, currentfriend, inputHendle, newMessage, sendMessage, message,onUpload,handleUpload,
             scrollRef, emojiSend, ImageSend,AudioSend,VideoSend,DocumentSend, activeUser, typing, myInfo, hide,
              setHide, themeSet, logout, handleImageClick, socket, setInCall,
               inCall, peerI, localVideoRef, remoteVideoRef, secondCall, setSecondCall,
                acceptCall, setAcceptCall, showCall, setShowCall ,notification,
                setNotification, videoOrAudio, setvideoOrAudio ,startCallTimer,callTimerRef,
                callDuration,setCallDuration,hideOption,returnClose,decrypted} = props;
        const filteredMessages = message.filter((msg) =>
            msg.message.text.toLowerCase().includes(searchTerm.toLowerCase())
        );
        const handleSearch = (value) => {
            setSearchTerm(value);
        };

        const handleHide = () => {
            if (inCall) {
                return;
            }
            setShowFriendInfo(!showFriendInfo);
        };
        const handleHide2 = (inp) => {
            if (!hide) {
                 setHide(true);
                 return;
            }
            if (inp === 'icon') {
                 setHide(false);
            }

       }
       const handleVCallClick = () => {
        if (inCall) {
            return;
        }
        setShowCall(!showCall);
        setvideoOrAudio(false);
        setCaller(true)

    };
    const handleACallClick = () => {
        //if the user isnt in the active user list  he's shown a toast message
        if (!activeUser.some((u) => u.userId === currentfriend._id)) {
            toast.error('User is not online');
            return;
        }
        if (inCall) {
            return;
        }
        console.log('all the info is here', currentfriend, activeUser, myInfo);
        console.log('bolean values are here', showCall, notification);
        setShowCall(!showCall);
        setvideoOrAudio(true);
        setCaller(true)
    };


useEffect(() => {
    //if typign is true count to 3 and dispatch typing clear
    if (typing) {
        setTimeout(() => {
            dispatch({
                type: 'TYPING_CLEAR',
                payload: {
                    typing: false,
                },
            });
        }, 2000);
    }
}, [typing]);

    useEffect(() => {
    }, [currentfriend]);
        return (
            <div className='col-9'>
                <div className='right-side'>
                    <input type="checkbox" id='dot' />
                    <div className='row'>
                        <div className='col-8'>
                            <div className='message-send-show' style={{ width: showFriendInfo ? 'calc(100% - 25%)' : '100%' }}>
                                <div className='header'>
                                    <div className='image-name' onClick={handleHide}>
                                        <div className='image'>
                                            <img src={`./images/${currentfriend.image}`} alt='' />
                                            {activeUser && activeUser.length > 0 && activeUser.some(u => u.userId === currentfriend._id) ? <div className='active-icon'></div> : ''}
                                        </div>
                                        <div className='name-date'>
                                            <h3>{currentfriend.userName}</h3>
                                            { activeUser && activeUser.length > 0 && activeUser.some(u => u.userId === currentfriend._id) ? 
                                            (
                                                <>
                                                {
                                                    typing ? (
                                                        <span>Typing ...</span>
                                                    ) : (
                                                        <span>Active now</span>
                                                    )
                                                }
                                            </>
                                            )
                                            :( <span>Last Seen {moment(currentfriend.lastSeen).startOf('mini').fromNow()}</span>)
                                            }
                                        </div>
                                    </div>
                                    <div className='icons' >
                                        <div className='icon audio'
                                        >
                                            <i className="material-symbols-outlined" style={{fontSize: '25px'}} >
                                            Add_Call
                                            </i>
                                        </div>
                                        <div className='icon video' onClick={handleACallClick}>
                                        <i className="material-symbols-outlined" style={{fontSize: '25px'}} >
                                        Video_Call
                                        </i>
                                        </div>
                                    </div>
                                </div>
                             {
  showCall && notification ? (
    <CallNotification currentfriend={currentfriend} setAcceptCall={setAcceptCall}
    rejectCall={setShowCall} onRespond={setShowCall} setNotification={setNotification}
     socket={socket} notification={notification} startCallTimer={startCallTimer} returnClose={returnClose} />
  ) : showCall ? (
    <Vacall
      currentfriend={currentfriend}
      activeUser={activeUser}
      myInfo={myInfo}
      socket={socket}
      videoOrAudio={videoOrAudio}
      setShowCall={setShowCall}
      showCall={showCall}
      setInCall={setInCall}
      caller={caller}
      setCaller={setCaller}
      peerI={peerI}
      localVideoRef={localVideoRef}
      remoteVideoRef={remoteVideoRef}
      acceptCall={acceptCall}
        setAcceptCall={setAcceptCall}
        inCall={inCall}
        startCallTimer={startCallTimer}
        callTimerRef={callTimerRef}
        callDuration={callDuration}
        setCallDuration={setCallDuration}
        decrypted= {decrypted}
    />
  ) : (
    <Message
    message={filteredMessages}
    currentfriend={currentfriend}
    scrollRef={scrollRef}
    typing={typing}
    handleImageClick={handleImageClick}
    friends={friends}
    sendMessage={sendMessage}
    hideOption={hideOption}
    socket={socket}
    decrypted={decrypted}
    />
  )
}
                                    <MessageSend
                                        inputHendle={inputHendle}
                                        newMessage={newMessage}
                                        sendMessage={sendMessage}
                                        emojiSend={emojiSend}
                                        ImageSend={ImageSend}
                                        DocumentSend={DocumentSend}
                                        onUpload={onUpload}
                                        AudioSend={AudioSend}
                                        currentfriend={currentfriend}
                                        VideoSend={VideoSend}

                                    />
                            </div>
                            <div className='col-4'>
                                {showFriendInfo && <FriendInfo message={message} currentfriend={currentfriend}
                                 activeUser={activeUser} handleSearch={handleSearch} searchTerm={searchTerm} />}
                            </div>
                        </div>
                    </div>
                 </div>
            </div>
        );
    };

    export default RightSide;
