import React, { useEffect } from 'react';
import moment from 'moment';
import { FaRegCheckCircle } from "react-icons/fa";
import { getUnseenMessageNumber } from '../store/actions/messengerAction';
import { useDispatch } from 'react-redux';

const Friends = (props) => {
    const { fndInfo, msgInfo } = props.friend;
    const [unseenMessage, setUnseenMessage] = React.useState(0);
    const myId = props.myId;
    const dispatch = useDispatch();
    const us = props.us;
    const { activeUser } = props;

    useEffect(() => {
        if (msgInfo) {
            dispatch(getUnseenMessageNumber(msgInfo.senderId, myId)).then((response) => {
                setUnseenMessage(response);
            });
        }
    }, [msgInfo]);

    function formatTime(createdAt) {
        const now = moment();
        const createdAtMoment = moment(createdAt);
        const diffInHours = now.diff(createdAtMoment, 'hours');
        if (diffInHours < 24) {
            return createdAtMoment.format('HH:mm');
        } else if (diffInHours < 24 * 7) {
            return createdAtMoment.format('dddd');
        } else if (diffInHours < 24 * 30) {
            return createdAtMoment.format('MMM');
        } else if (diffInHours < 24 * 365) {
            return createdAtMoment.format('MMM');
        } else {
            return createdAtMoment.format('YYYY');
        }
    }

    return (
        <div className='friend'>
            <div className='friend-image'>
                <div className='image'>
                    <img src={`./images/${fndInfo.image}`} alt='' />
                    {activeUser && activeUser.length > 0 && activeUser.some(u => u.userId === fndInfo._id) && (
                        <div className='active_icon'></div>
                    )}
                </div>
            </div>

            <div className='friend-name-seen'>
                <div className='friend-name'>
                    <h4 className={msgInfo?.senderId !== myId && msgInfo?.status !== undefined && msgInfo.status !== 'seen' ? 'unseen_message Fd_name' : 'Fd_name'}>
                        {fndInfo.userName}
                    </h4>
                    <div className='msg-time'>
                        {msgInfo && msgInfo.message.text ? (
                            <span className={msgInfo?.senderId !== myId && msgInfo?.status !== undefined && msgInfo.status !== 'seen' ? 'unseen_message' : ''}>
                                {msgInfo.message.text.slice(0, 10)}
                            </span>
                        ) : msgInfo && msgInfo.message.image ? (
                            <span>Send an image</span>
                        ) : msgInfo && msgInfo.message.audio ? (
                            <span>Send an audio</span>
                        ) : msgInfo && msgInfo.message.document ? (
                            <span>Send an document</span>
                        )
                        : msgInfo && msgInfo.message.video ? (
                            <span>Send an video</span>
                        ) : (
                            <span>Connect You</span>
                        )}
                        <span> ... </span>
                        <br />
                    </div>
                </div>

                <div className='number-of-message'>
                    <span>
                        {msgInfo ? formatTime(msgInfo.createdAt) : formatTime(fndInfo.createdAt)}
                    </span>

                    {myId === msgInfo?.senderId ? (
                        <div className='seen-unseen-icon'>
                            {msgInfo.status === 'seen' ? (
                                <img src={`./images/${fndInfo.image}`} alt='' />
                            ) : msgInfo.status === 'delivared' ? (
                                <div className='delivared'><FaRegCheckCircle /></div>
                            ) : (
                                <div className='unseen'></div>
                            )}
                        </div>
                    ) : (
                        <div className='seen-unseen-icon'>
                            {msgInfo?.status !== undefined && msgInfo?.status !== 'seen' && (
                                <div className='seen-icon'>
                                    {unseenMessage > 0 ? <div className='unseen-message-count'>{unseenMessage}</div> : ' '}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Friends;
