// FriendList.js

import React ,{ useState } from 'react';
import moment from 'moment';
import Friends from './Friends';
import UserList from './UserList'; // Import the UserList component

const FriendList = ({ friends, activeUser, myInfo, handleOnClickFriend, hideUsers, dispatch, users,currentfriend ,setAddUser}) => {

  const [currentUser, setCurrentUser] = useState('');

  return (
    <>
      {hideUsers ? (
        <div className='friends'>
          {friends && friends.length > 0 ? (
            friends
              .sort((a, b) => {
                const lastMsgTimeA = a.msgInfo ? moment(a.msgInfo.createdAt) : moment(a.fndInfo.createdAt);
                const lastMsgTimeB = b.msgInfo ? moment(b.msgInfo.createdAt) : moment(b.fndInfo.createdAt);
                return lastMsgTimeB - lastMsgTimeA;
              })
              .map((fd) => (
                <div
                  key={fd.fndInfo._id}
                  className={currentfriend._id === fd.fndInfo._id ? 'hover-friend active' : 'hover-friend'}
                  onClick={() => handleOnClickFriend(fd)}
                >
                  <Friends activeUser={activeUser} friend={fd} myId={myInfo.id} />
                </div>
              ))
          ) : (
            'No Friend'
          )}
        </div>
      ) : (
        <UserList dispatch={dispatch} friends={friends} users={users} myInfo={myInfo} currentUser={currentUser} setCurrentUser={setCurrentUser}
        setAddUser={setAddUser} />
      )}
    </>
  );
};

export default FriendList;
