import { getUsers } from '../store/actions/messengerAction';
import React, { useEffect } from 'react';
import Users from './Users';
const UserList = (props) => {

    const {dispatch, friends, users, myInfo,currentUser,setCurrentUser} = props;
    const friendIds = friends.map(friend => friend.fndInfo._id);

    useEffect(() => {
        dispatch(getUsers(friendIds, myInfo.id));
    }, []);

const handleOnClickUser = (userId) => {

    const user = users.find(user => user.fndInfo._id === userId);
    setCurrentUser(user);
    props.setAddUser(user);

}

    return (
        <div className='users'>
            {
                users && users.map(user => (
                    <div key={user.fndInfo._id}
                        className={currentUser._id === user.fndInfo._id ? 'hover-friend active' : 'hover-friend'}
                        onClick={() => handleOnClickUser(user.fndInfo._id)}
                    >
                        <Users user={user} myId={myInfo.id} activeUser={friends} us="true" />
                    </div>
                ))
            }
        </div>
    );
}

export default UserList;
