import React,{ useEffect,useState,useRef } from 'react';
import RightSide from './RightSide';
import {useDispatch ,useSelector } from 'react-redux';
import { getFriends,messageSend,getMessage,ImageMessageSend,seenMessage,updateMessage,getTheme
,themeSet,setLastSeen ,generateKeyFiles,decryptData,sendAudioMessage,AudioMessageSend,DocumentMessageSend,VideoMessageSend} from '../store/actions/messengerAction';
import {userLogout } from '../store/actions/authAction';
import Peer from 'peerjs';
import toast,{Toaster} from 'react-hot-toast';
import {io} from 'socket.io-client';
import useSound from 'use-sound';
import notificationSound from '../audio/notification.mp3';
import sendingSound from '../audio/sending.mp3';
import greenandother from "../imagelog/greenandother.png";
import AddChoice from './AddChoice';
import LeftSide from './LeftSide';
import SendEmail from './SendEmail';
import Profile from './Profile';
// import { generateKeyFiles,encryptString,test,deleteFiles,decryptString} from '../endToEnd/Encryption';
const Messenger = () => {

 const [notificationSPlay] = useSound(notificationSound);
 const [sendingSPlay] = useSound(sendingSound);

 const scrollRef = useRef();
 const socket = useRef();
 const [showProfile, setShowProfile] = useState(false); // State to control visibility of the profile


 const {users,friends,message,mesageSendSuccess,typing,themeMood,new_user_add,alert,publicKey,decrypted,type} = useSelector(state => state.messenger ); // Get friends and messages from the Redux store

 const {myInfo} = useSelector(state => state.auth); // Get the user's information from the Redux store
 const [currentfriend, setCurrentFriend] = useState('');    // State to store the current friend
 const [showAddFriendIcon, setShowAddFriendIcon] = useState(false); // State to control visibility of the add friend icon

 const [newMessage, setNewMessage] = useState(''); // State to store the new message

 const [activeUser, setActiveUser] = useState([]); // State to store the active users
 const [socketMessage, setSocketMessage] = useState(''); // State to store the message received from the socket

 const [inCall, setInCall] = useState(false); // State to control visibility of Call

 const [peer, setPeer] = useState(null); // State to store the peer connection
 const localVideoRef = useRef(null); // Reference to the local video element
 const remoteVideoRef = useRef(null); // Reference to the remote video element
 const [secondCall, setSecondCall] = useState(false); // State to control the second call
 const [acceptCall, setAcceptCall] = useState(); // State to control the acceptance of a call
 const [peerI, setPeerI] = useState(null); // State to store the peer connection
 const [showCall, setShowCall] = useState(false); // State to control visibility of Call
 const [caller, setCaller] = useState(false); // State to control visibility of Call
 const [notification, setNotification] = useState(false); // State to control visibility of Call
 const friendRef = useRef([]); // Reference to the friends array
 const [videoOrAudio, setvideoOrAudio] = useState(true); // State to control visibility of Call
 const [callDuration, setCallDuration] = useState(0);
 const callTimerRef = useRef(null); // Ref to hold the interval ID for the call timer

 const [hideUsers, setHideUsers] = useState(true); // State to control visibility of the active users

 const [addUser, setAddUser] = useState(''); // State to control visibility of the add user form
 const [sendEmail, setSendEmail] = useState(false); // State to control visibility of the send email form
 const handleImageClick = (imageURL) => {

};

const startCallTimer = () => {
     callTimerRef.current = setInterval(() => {
       setCallDuration((prevDuration) => prevDuration + 1); // Increment call duration every second
     }, 1000);
   };

   const [hideOption, setHideOption] = useState(false); // State to control visibility of the options menu
   const handleHide = (inp) => {
     setHideOption(!hideOption);
     if (!hide) {
          setHide(true);
          return;
     }
     if (inp === 'icon') {
          setHide(false);
     }
 
 };
useEffect(() => {
     const p =() => {
          const peer = new Peer(myInfo.id);
          peer.on('open', (id) => {
             console.log('My peer ID is: ' + id);
         });
          return peer;
     }

     if (!friends || friends.length === 0) {
          return;
     }
     const peer = p();
     setPeer(peer);

     friendRef.current = friends;
     peer.on('call', (call) => {
          const curre = friendRef.current.find((f) => f.fndInfo._id === call.peer);
         setCurrentFriend(curre.fndInfo);

          setAcceptCall(true);
          navigator.mediaDevices.getUserMedia({ video: videoOrAudio, audio: true })
         .then((stream) => {
                if (localVideoRef.current) {
                    localVideoRef.current.srcObject = stream;
                    }
             call.answer(stream);
         call.on('stream', (remoteStream) => {
             if (remoteVideoRef.current) {
                 remoteVideoRef.current.srcObject = remoteStream;
             }
         });
         }
         )
         .catch((error) => {
             console.error('Error accessing media devices:', error);
         });
             setSecondCall(true);
     });
 
 
     setPeerI(peer);
     return () => {
          returnClose();
          peer.destroy();
      };
 
     }, [friends]);

const returnClose = () => {
     setCallDuration(0);
     setShowCall(false);
     setInCall(false);
     if (localVideoRef.current && localVideoRef.current.srcObject) {
       const tracks = localVideoRef.current.srcObject.getTracks();
       tracks.forEach(track => track.stop());
        }
     if (remoteVideoRef.current && remoteVideoRef.current.srcObject) {
         const tracks = remoteVideoRef.current.srcObject.getTracks();
         tracks.forEach(track => track.stop());
     }
     if (remoteVideoRef.current) {
         remoteVideoRef.current.srcObject = null;
     }
     if (localVideoRef.current) {
         localVideoRef.current.srcObject = null;
     }
     setSecondCall(false);
     //triggerMethod();
};

 useEffect(() => {
     socket.current = io('https://midad.tn', {
     });
    // socket.current = io(process.env.SOCKET_URL, {
   //  });
     dispatch(generateKeyFiles(myInfo.id));

      //socket.current = io('http://localhost:7000', {
 // });
     // socket.current = io('http://localhost:7000', {
  //});

 Notification.requestPermission();

 // Client-side code
socket.current.on('user-disconnected', (userId) => {
     dispatch(setLastSeen(userId));

 });

     socket.current.on('delete-messaged', (EncryptedData) => {
          dispatch(decryptData(EncryptedData, myInfo.id,'DELETE_MESSAGE'));
     });


    socket.current.on('typingMessageGet',EncryptedData => {
     dispatch(decryptData(EncryptedData, myInfo.id,'TYPING_MESSAGE'));
 })

 socket.current.on('msgSeenResponse', EncryptedData => {
     dispatch(decryptData(EncryptedData, myInfo.id,'MESSAGE_SEEN'));
 })

 socket.current.on('msgDelivaredResponse', EncryptedData => {
     dispatch(decryptData(EncryptedData, myInfo.id,'DELIVARED_MESSAGE'));
 })

 socket.current.on('new_user_add',encryptedData => {
     dispatch(decryptData(encryptedData, myInfo.id,'new_user_add'));
})
socket.current.on('getUser', (users)=>{
     let filterUser = users.filter(u => u.userId !== myInfo.id)
     setActiveUser(filterUser);
})
socket.current.on('getMessage', (EncryptedData) => {
     //the message text is not encrypted so don't need to decrypt it
     dispatch(decryptData(EncryptedData, myInfo.id, 'MESSAGE_GET'));
   });


 socket.current.on('seenSuccess', EncryptedData => {
     dispatch(decryptData(EncryptedData, myInfo.id,'SEEN_ALL'));
 })

 socket.current.on('showCallNotification', EncryptedData => {
     dispatch(decryptData(EncryptedData, myInfo.id,'SHOW_CALL'));

     });

 socket.current.on('hangupNotification', (EncryptedData) => {
     dispatch(decryptData(EncryptedData, myInfo.id,'HANGUP'));
   });

   //respond to this socket.to(user.socketId).emit('added-friend', encryptedData);
 socket.current.on('added-friend', EncryptedData => {
     dispatch(decryptData(EncryptedData, myInfo.id,'NEW_FRIEND_ADD'));
   });
   socket.current.on('edit-messaged', EncryptedData => {
     console.log(EncryptedData);
     dispatch(decryptData(EncryptedData, myInfo.id,'MESSAGE_UPDATE_SUCCESS'));
   });
   //socket.to(user.socketId).emit('messageWithId', encryptedMsg); respond to this event
     socket.current.on('messageWithId', EncryptedData => {
          dispatch(decryptData(EncryptedData, myInfo.id,'MESSAGE_WITH_ID'));
     }
     );
},[]);

useEffect(() => {
     if (decrypted) {
         console.log(type);
         dispatch({
             type: type,
             payload: {
                 decrypted
             }
         });
         if (type === 'MESSAGE_GET') {
             // Remove " and / from the message text
             if (decrypted.message.text) {
                 decrypted.message.text = decrypted.message.text.replace(/"/g, '');
             }
             
             // Check if the message contains an audio file
            
 
             setSocketMessage(decrypted);
         } else if (type === 'SHOW_CALL') {
             setCurrentFriend(decrypted);
             setNotification(true);
             setShowCall(true);
             setCaller(false);
             if (secondCall) {
                 return;
             }
             setInCall(true);
             setAcceptCall(false);
             setCaller(true);
         } else if (type === 'HANGUP') {
             toast.error(`${decrypted.userName} has ended the call`);
 
             if (localVideoRef.current && localVideoRef.current.srcObject) {
                 const tracks = localVideoRef.current.srcObject.getTracks();
                 tracks.forEach(track => track.stop());
             }
 
             if (remoteVideoRef.current && remoteVideoRef.current.srcObject) {
                 const tracks = remoteVideoRef.current.srcObject.getTracks();
                 tracks.forEach(track => track.stop());
             }
 
             if (remoteVideoRef.current) {
                 remoteVideoRef.current.srcObject = null;
             }
             if (localVideoRef.current) {
                 localVideoRef.current.srcObject = null;
             }
 
             setInCall(false);
             setShowCall(false);
             setSecondCall(false);
         }
 
         dispatch({
             type: 'DECRYPT_CLEAR'
         });
     }
 }, [decrypted]);
 
useEffect(() => {
    if(socketMessage && currentfriend){
         if(socketMessage.senderId === currentfriend._id && socketMessage.reseverId === myInfo.id){
              dispatch({
                   type: 'SOCKET_MESSAGE',
                   payload : {
                        message: socketMessage
                   }
              })
           }
     }
     setSocketMessage('');
 },[socketMessage]);


 useEffect(() => {
     if (publicKey){
     socket.current.emit('addUser', myInfo.id, myInfo,publicKey);
     }

     },[publicKey]);

useEffect(() => {
     socket.current.emit('infoChanged', myInfo.id, myInfo);
},[myInfo]);
useEffect(() => {
     const gg= friends.find(f => f.fndInfo._id === socketMessage.senderId);
  if (
    socketMessage &&
    socketMessage.reseverId === myInfo.id
  ) {
    notificationSPlay();

    if ("Notification" in window && Notification.permission === "granted") {
          const notification = new Notification(`${socketMessage.senderName} sent a new message`);

          notification.onclick = (e) => {
               e.preventDefault();
               window.focus();
          }
    }

    toast.success(`${socketMessage.senderName} sent a new message`);
    dispatch(updateMessage(socketMessage));
    socket.current.emit("delivaredMessage", socketMessage);
    dispatch({
      type: "UPDATE_FRIEND_MESSAGE",
      payload: {
        msgInfo: socketMessage,
        status: "delivared"
      }
    });
  }
}, [socketMessage]);



 

 const inputHendle = (e) => {
     setNewMessage(e.target.value);

     socket.current.emit('typingMessage',{
          senderId : myInfo.id,
          reseverId : currentfriend._id
     })
 }

 const sendMessage = (data) => {
     console.log("sendMessage called with data:", data);
     sendingSPlay();
   
     if (data === '') {
       console.log("Sending text message:", newMessage ? newMessage : '👍');
       socket.current.emit('sendMessage', {
         senderId: myInfo.id,
         senderName: myInfo.userName,
         reseverId: currentfriend._id,
         time: new Date(),
         message: {
           text: newMessage ? newMessage : '👍',
           image: ''
         }
       });
       const data1 = {
         senderName: myInfo.userName,
         reseverId: currentfriend._id,
         message: newMessage ? newMessage : '👍'
       };
       dispatch(messageSend(data1));
     } else if (data.audio) {
       console.log("Sending audio message with blob:", data.audio);
       const formData = new FormData();
       formData.append('audio', data.audio);
       formData.append('senderName', myInfo.userName);
       formData.append('reseverId', data.receiverId);
   
       // Log the FormData content
       for (let pair of formData.entries()) {
         console.log(pair[0] + ': ' + pair[1]);
       }
       socket.current.emit('sendMessage', {
          senderId: myInfo.id,
          senderName: myInfo.userName,
          reseverId: data.receiverId,
          time: new Date(),
          message: { text: 'Audio message', audio :data.message ? data.message.audio : data}
        });
   
       dispatch(sendAudioMessage(formData));
     } else {
       console.log("Sending other type of message:", data);
       socket.current.emit('sendMessage', {
         senderId: myInfo.id,
         senderName: myInfo.userName,
         reseverId: data.receiverId,
         time: new Date(),
         message: {
           text: data.message ? data.message.text : data,
           image: ''
         }
       });
       const data1 = {
         senderName: myInfo.userName,
         reseverId: data.receiverId,
         message: data.message ? data.message.text : data,
         forwarded: data.forwarded ? data.forwarded : false
       };
       dispatch(messageSend(data1));
     }
     setNewMessage('');
     console.log("New message reset to empty");
   };
 
useEffect(() => {
     //this is aimed to send a socket event with the message id
     if(message.length > 0){
          //find the message in message list where its text is the same as the newMessage
          const msg = message.find(m => m.message.text === newMessage);
          if(msg){
               socket.current.emit('messageWithId',msg);
          }
     }
},[message,newMessage]);
 useEffect(() => {
      if(mesageSendSuccess && message.length > 0){

          socket.current.emit('sendMessage', message[message.length -1 ]);
          console.log(message[message.length -1])
          dispatch({
               type: 'UPDATE_FRIEND_MESSAGE',
               payload : {
                    msgInfo : message[message.length -1]
               }
          })
          dispatch({
               type: 'MESSAGE_SEND_SUCCESS_CLEAR'
          })
      }
},[mesageSendSuccess,message]);

     const dispatch = useDispatch();
     useEffect(() => {
          dispatch(getFriends());
          dispatch({type:'NEW_USER_ADD_CLEAR'})
     },[new_user_add]);

     useEffect(() => {
          if (type === 'NEW_FRIEND_ADD') {
               toast.success('New friend added you');
               dispatch(getFriends());
               dispatch({ type: 'NEW_FRIEND_ADD_CLEAR' });
          }
     }, [type]);
//here or in teh userList
     useEffect(() => {
          dispatch(getMessage(currentfriend._id));
          if(friends.length > 0){

          }
      },[ currentfriend?._id]);


      useEffect(() => {
          if (message  && currentfriend && message.length > 0) {
              const lastMessage = message[message.length - 1];
              // Check if the last message is sent by the current friend and is not seen
              if (lastMessage.senderId !== myInfo.id && lastMessage.status !== 'seen') {
                  // Dispatch action to update message status
                  dispatch({
                      type: 'UPDATE',
                      payload: {
                          id: currentfriend._id
                      }
                  });
                  // Emit 'seen' event to socket server
                  socket.current.emit('seen', { senderId: currentfriend._id, reseverId: myInfo.id });
                  // Dispatch action to mark the last message as seen
                  dispatch(seenMessage({ _id: lastMessage._id }));
              }
          }
      }, [message, currentfriend]);


 
      useEffect(() => {
          scrollRef.current?.scrollIntoView({behavior: 'smooth'}) 
      },[ message]);
 

     const emojiSend = (emu) => {
          setNewMessage(`${newMessage}`+  emu);
          socket.current.emit('typingMessage',{
               senderId : myInfo.id,
               reseverId : currentfriend._id
          })
     }

     const AudioSend = (data) => {
          if (data.audio) {
            sendingSPlay();
            const audioName = `audio_${Date.now()}.wav`;
        
            socket.current.emit('sendMessage', {
              senderId: myInfo.id,
              senderName: myInfo.userName,
              reseverId: currentfriend._id,
              time: new Date(),
              message: {
                text: '',
                image:'',
                audio: audioName
              }
            });
        
            const formData = new FormData();
            formData.append('senderName', myInfo.userName);
            formData.append('audioName', audioName);
            formData.append('receiverId', currentfriend._id);
            formData.append('audio', data.audio);
        
            dispatch(AudioMessageSend(formData));
          }
        };
        


     const ImageSend = (e) => {

          if(e.target.files.length !== 0){
               sendingSPlay();
               const imagename = e.target.files[0].name;
               const newImageName = Date.now() + imagename;

               socket.current.emit('sendMessage',{
                    senderId: myInfo.id,
                    senderName: myInfo.userName,
                    reseverId: currentfriend._id,
                    time: new Date(),
                    message : {
                         text : '',
                         image : newImageName
                    }
               })

               const formData = new FormData();

               formData.append('senderName',myInfo.userName);
               formData.append('imageName',newImageName);
               formData.append('reseverId',currentfriend._id);
               formData.append('image', e.target.files[0]);
               dispatch(ImageMessageSend(formData));

          }

     }

     const VideoSend = (e) => {
          if (e.target.files.length !== 0) {
              sendingSPlay(); // Play the sending sound or any action you want to perform
      
              const videoname = e.target.files[0].name;
              const newVideoName = Date.now() + videoname;
      
              socket.current.emit('sendMessage', {
                  senderId: myInfo.id,
                  senderName: myInfo.userName,
                  reseverId: currentfriend._id,
                  time: new Date(),
                  message: {
                      text: '',
                      video: newVideoName // Attach the video file name to the message object
                  }
              });
      
              const formData = new FormData();
      
              formData.append('senderName', myInfo.userName);
              formData.append('videoName', newVideoName); // Attach the video name
              formData.append('reseverId', currentfriend._id);
              formData.append('video', e.target.files[0]); // Attach the video file itself
      
              dispatch(VideoMessageSend(formData)); // Dispatch the action to send the video
          }
      };
      


     const DocumentSend = (e) => {
          if (e.target.files.length !== 0) {
            sendingSPlay(); // If you have a sound or animation for sending
            const documentName = e.target.files[0].name;
            const newDocumentName = Date.now() + documentName;
        
            socket.current.emit('sendMessage', {
              senderId: myInfo.id,
              senderName: myInfo.userName,
              reseverId: currentfriend._id,
              time: new Date(),
              message: {
                text: '',
                document: newDocumentName,
              }
            });
        
            const formData = new FormData();
            formData.append('senderName', myInfo.userName);
            formData.append('documentName', newDocumentName);
            formData.append('reseverId', currentfriend._id);
            formData.append('document', e.target.files[0]);
            dispatch(DocumentMessageSend(formData));
          }
        };
        







        
     const [hide, setHide] = useState(true);

     const logout = () => {
          dispatch(userLogout());
          socket.current.emit('logout', myInfo.id);
     }

     useEffect(() => {
         dispatch(getTheme());
      },[ ]);
 
      const search = (e) => {
          let getFriendClass, frienNameClass;
      
          if (hideUsers) {
              getFriendClass = document.getElementsByClassName('hover-friend');
              frienNameClass = document.getElementsByClassName('Fd_name');
          } else {
              getFriendClass = document.getElementsByClassName('user');
              frienNameClass = document.getElementsByClassName('usr-name');
          }
          for (let i = 0; i < getFriendClass.length && i < frienNameClass.length; i++) {
              let text = frienNameClass[i].innerText.toLowerCase();
              if (text.indexOf(e.target.value.toLowerCase()) > -1) {
                  getFriendClass[i].style.display = '';
              } else {
                  getFriendClass[i].style.display = 'none';
              }
          }
      };
      

const handleOnClickFriend = (fd) => {

     if (showCall) {
     return;
     }
     setCurrentFriend(fd.fndInfo);

}
const handleClickActiveFriend = (user) => {
     if (showCall) {
          return;
     }
     const curre = friendRef.current.find((f) => f.fndInfo._id === user.userId);
     if (curre) {
     setCurrentFriend(curre.fndInfo);
     }
}

const handleHide2 = (inp) => {
     if (!hide) {
          setHide(true);
          return;
     }
     if (inp === 'icon') {
          setHide(false);
     }

}

  return (
     <div className={themeMood === 'dark' ? 'messenger theme' : 'messenger' } onClick={() => handleHide('')} >
            <Toaster
            position={'top-right'}
            reverseOrder = {false}
            toastOptions={{
                 style : {
                      fontSize : '18px'
                 }
            }}

            />

<div className='row'>
     <div className='col-3'>
               <LeftSide
                    activeUser={activeUser} hideUsers={hideUsers} setHideUsers={setHideUsers} myInfo={myInfo} logout={logout} search={search}
                    friends={friends} handleOnClickFriend={handleOnClickFriend} users={users} currentfriend={currentfriend}
                    setAddUser={setAddUser} dispatch={dispatch} handleHide2={handleHide2} hide={hide} themeSet={themeSet}
                    greenandother={greenandother} inCall={inCall} handleClickActiveFriend={handleClickActiveFriend}
                    showAddFriendIcon={showAddFriendIcon} setShowAddFriendIcon={setShowAddFriendIcon}
                    showProfile={showProfile} setShowProfile={setShowProfile}
                />
                 </div>
                 {
                 hideUsers ? (
     currentfriend ? (
        <RightSide
            friends={friends} currentfriend={currentfriend} inputHendle={inputHendle} newMessage={newMessage} sendMessage={sendMessage}
            message={message} scrollRef={scrollRef} emojiSend={emojiSend}  ImageSend={ImageSend} DocumentSend={DocumentSend} VideoSend={VideoSend} activeUser={activeUser}
            typing={typing} myInfo={myInfo} hide={hide} setHide={setHide} dispatch={dispatch} themeSet={themeSet}
            logout={logout} handleImageClick={handleImageClick} socket={socket} setInCall={setInCall} inCall={inCall}
            peer={peer} localVideoRef={localVideoRef} remoteVideoRef={remoteVideoRef} secondCall={secondCall} setSecondCall={setSecondCall}
            acceptCall={acceptCall} setAcceptCall={setAcceptCall} showCall={showCall} setShowCall={setShowCall} caller={caller}
            setCaller={setCaller} peerI={peerI} notification={notification} setNotification={setNotification} videoOrAudio={videoOrAudio}
            setvideoOrAudio={setvideoOrAudio} callTimerRef={callTimerRef} callDuration={callDuration}
            setCallDuration={setCallDuration} startCallTimer={startCallTimer} hideOption={hideOption} returnClose={returnClose}
            decrypted ={decrypted} AudioSend= {AudioSend}
        />
     ) : (
            <div className='col-9'>
               <p> select a friend or add a new one</p>
               </div>
       )
    ) : (
     !hideUsers && addUser !== '' ? (
          <AddChoice
              myInfo={myInfo}
              addUser={addUser}
              dispatch={dispatch}
              setHideUsers={setHideUsers}
              setCurrentFriend={setCurrentFriend}
              setAddUser={setAddUser}
              setShowAddFriendIcon={setShowAddFriendIcon}
              socket={socket}
          />
      ) : (
          <div className='col-9'>
               <div className='offer-to-add-friend'>
                    <div className='add-friend'>
                         {!sendEmail && <p>select a user to be a friend with</p>}
                         {!sendEmail &&
                         <div className='invite' onClick={() => setSendEmail(true)}>
                               <a href="#" >Or invite a friend by email</a>
                         </div>
                         }
                         {sendEmail && <SendEmail setSendEmail={setSendEmail} alert1={alert} />}
                    </div>
               </div>
          </div>
      )
    )
}
{ showProfile &&
     <Profile myInfo={myInfo} setShowProfile={setShowProfile} />
}
            </div>
       </div>
  )
};
export default Messenger;