import React, { useState } from 'react';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const Forward = (props) => {
    const { currentfriend, myInfo, friends, sendMessage, setForwardOrNot, messageToForward } = props;
    const [selectedFriends, setSelectedFriends] = useState([]);
    console.log(messageToForward)
    // Function to handle toggling friend selection
    const handleToggleFriendSelection = (friendId) => {
        setSelectedFriends((prevSelected) => {
            if (prevSelected.includes(friendId)) {
                return prevSelected.filter((id) => id !== friendId);
            } else {
                return [...prevSelected, friendId];
            }
        });
    };

    // Function to handle forwarding message to selected friends
    const handleForwardMessageToFriends = () => {
        console.log('Forwarding message to friends:', selectedFriends);
        console.log('Message to forward:', messageToForward);
        selectedFriends.forEach((friendId) => {
            const friend = friends.find((f) => f.fndInfo._id === friendId);
            if (friend) {
                console.log('Forwarding message to:', friend.fndInfo.userName);
                sendMessage({
                    senderId: myInfo.id,
                    senderName: myInfo.userName,
                    receiverId: friend.fndInfo._id,
                    createdAt: new Date(),
                    message : {
                        text : messageToForward ? messageToForward : '👍',
                        image : '',
                        audio: messageToForward?.audio || '',
                        document: messageToForward?.document || '',
                        video: messageToForward?.video || '',

                        
                   },
                   forwarded : true
                });
            }
        });
        setForwardOrNot(false);
    };

    return (
        <div className='forward-container'>
            <div className='forward-header'>
                <h2>Select Friends to Forward the Message:</h2>
            </div>
            <div className='friends'>
                {friends && friends.length > 0 ? (
                    friends.map((friend) => (
                        <div
                            key={friend.fndInfo._id}
                            className='friend-item'
                            onClick={() => handleToggleFriendSelection(friend.fndInfo._id)}
                        >
                            {selectedFriends.includes(friend.fndInfo._id) ? (
                                <CheckBoxRoundedIcon />
                            ) : (
                                <CheckBoxOutlinedIcon />
                            )}
                            <span>{friend.fndInfo.userName}</span>
                        </div>
                    ))
                ) : (
                    'No Friends'
                )}
            </div>
            <div className='choice'>
                <button onClick={() => setForwardOrNot(false)}>Cancel</button>
                <button onClick={handleForwardMessageToFriends}>Forward</button>
            </div>
        </div>
    );
};

export default Forward;
