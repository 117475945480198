export const FRIEND_GET_SUCCESS = 'FRIEND_GET_SUCCESS'
export const MESSAGE_GET_SUCCESS = 'MESSAGE_GET_SUCCESS'
export const MESSAGE_SEND_SUCCESS = 'MESSAGE_SEND_SUCCESS'
export const SOCKET_MESSAGE = 'SOCKET_MESSAGE'
export const UPDATE_FRIEND_MESSAGE = 'UPDATE_FRIEND_MESSAGE'
export const MESSAGE_SEND_SUCCESS_CLEAR = 'MESSAGE_SEND_SUCCESS_CLEAR'
export const SEEN_MESSAGE = 'SEEN_MESSAGE'
export const DELIVARED_MESSAGE = 'DELIVARED_MESSAGE'
export const UPDATE = 'UPDATE'
export const MESSAGE_GET_SUCCESS_CLEAR = 'MESSAGE_GET_SUCCESS_CLEAR'
export const SEEN_ALL = 'SEEN_ALL'
export const THEME_GET_SUCCESS = 'THEME_GET_SUCCESS'
export const THEME_SET_SUCCESS = 'THEME_SET_SUCCESS'
export const ADD_FRIEND_SUCCESS = 'ADD_FRIEND_SUCCESS'
export const UPLOAD_AUDIO_SUCCESS = 'UPLOAD_AUDIO_SUCCESS';
export const UPLOAD_AUDIO_FAILURE = 'UPLOAD_AUDIO_FAILURE';