import React, { useState, useEffect } from 'react';
import useSound from 'use-sound';
import phoneSound from '../audio/dded.mp3';
import sendingSound from '../audio/sending.mp3';
import {toast} from 'react-hot-toast';
const CallNotification = ({ currentfriend, setAcceptCall, setNotification, socket ,returnClose,startCallTimer}) => {
    const [phoneSPlay,{stop}] = useSound(phoneSound);
    const [sendingSPlay] = useSound(sendingSound);
    const [aC, setAC] = useState('');

    useEffect(() => {
        phoneSPlay();
    
        // Set a timeout for 10 seconds to cancel the call request if not accepted
        const timeoutId = setTimeout(() => {
          toast.error('Call request timed out');
          setAC('timeout');
          setNotification(false);
          returnClose();
        }, 9000);
        return () => {
          stop();
          clearTimeout(timeoutId); // Clear the timeout when the component unmounts or the call is accepted/rejected
        };
      }, [phoneSPlay, stop, returnClose]);

    const handleCall = () => {
        setAC('accept');
        setAcceptCall(true);
    };

    const handleReject = () => {
        setAC('reject');
        setAcceptCall(false);
    };

    useEffect(() => {
        const handleResponse = () => {
            if (aC) {
                socket.current.emit('callRespons', { response: aC , caller: currentfriend });
                setNotification(false);
                startCallTimer();
                if (aC === 'reject') {
                  returnClose();
              }
            }

        };

        handleResponse();

        return () => {
            socket.current.off('incomingCall', handleResponse);
        };
    }, [aC, currentfriend, socket]);

    return (
        <div className="callNotification">
          <div className ='callForm'>
            <p>{`Do you want to accept a call from ${currentfriend.userName}?`}</p>
            <div className='callButtons'>
              <button onClick={handleCall} className='acceptCallButton'>Accept</button>
              <button onClick={handleReject} className='rejectCallButton'>Reject</button>
            </div>
          </div>
        </div>
    );
};

export default CallNotification;
