import React,{ useEffect } from 'react';
import moment from 'moment';
import { FaRegCheckCircle } from "react-icons/fa";
import {getUnseenMessageNumber} from '../store/actions/messengerAction';
import {useDispatch} from 'react-redux';
const Users = (props) => {
     const {fndInfo,commonFriends} = props.user;
     const [unseenMessage, setUnseenMessage] = React.useState(0);
     const myId = props.myId;
     const dispatch = useDispatch();
     const us = props.us;
     const {activeUser} = props;





     function formatTime(createdAt) {
          const now = moment();
          const createdAtMoment = moment(createdAt);
          const diffInHours = now.diff(createdAtMoment, 'hours');
          if (diffInHours < 24) {
            return createdAtMoment.format('HH:mm'); // Show exact time if less than 24 hours
          } else if (diffInHours < 24 * 7) {
            return createdAtMoment.format('dddd '); // Show day of the week and time if less than a week
          } else if (diffInHours < 24 * 30) {
            return createdAtMoment.format('MMM'); // Show month, day, and time if less than a month
          } else if (diffInHours < 24 * 365) {
            return createdAtMoment.format('MMM'); // Show month, day, year, and time if less than a year
          } else {
            return createdAtMoment.format('YYYY'); // Show full date and time if more than a year
          }
        }
  return (

       <div className='user' >
            <div className='user-image'>
                 <div className='image'>
                 <img src={`./images/${fndInfo.image}`} alt='' />
                 {
                     activeUser && activeUser.length > 0 && activeUser.some(u => u.userId === fndInfo._id ) ?  <div className='active_icon'></div> : ''
                 }

                 </div>
            </div>


            <div className='user-name-seen'>
                 <div className='user-name'>
                      <h4  className='usr-name'>{fndInfo.userName}</h4>


                      <div className='msg-time'>
                        <span>
                            {commonFriends} common friends
                        </span>
                      </div>
                 </div>
            </div>

       </div>
  )
};

export default Users;