import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { sendInvitation,clearAlert } from '../store/actions/messengerAction';

const SendEmail = (props) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const { setSendEmail,alert1 } = props;

  useEffect(() => {
      if (alert1 === 'Invitation sent') {
        alert.show('Invitation sent successfully');
        setSendEmail(false);
    }
    if (alert1.errorMessage) {
        const errorMessages = alert1.errorMessage;
      alert.error(errorMessages);
      setSendEmail(false);
    }
    dispatch(clearAlert());
  }, [alert1]);

  const inputHandle = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      alert.error('Email is required');
      return;
    }
    dispatch(sendInvitation(email, () => alert.show('Invitation sent successfully')));
  };

  const handleCancel = () => {
    setEmail(''); // Clear the email input field
    setSendEmail(false); // Set sendEmail to false to hide the SendEmail component
  };

  return (
    <div className='send-email'>
      <div className='send-email-container'>
        <div className='send-email-header'>
          <h3>Send Email</h3>
          <button onClick={handleCancel}>X</button>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            type='email'
            placeholder='Enter email address'
            value={email}
            onChange={inputHandle}
          />
          <button type='submit'>Send</button>
        </form>
      </div>
    </div>
  );
}

export default SendEmail;
