import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import Forward from './forward';
import moment from 'moment';
import toast from 'react-hot-toast';
import { deleteMessage, updateMsg } from '../store/actions/messengerAction';

const Message = ({ message, currentfriend, scrollRef, handleImageClick, friends, sendMessage, hideOption, socket }) => {
    const { myInfo } = useSelector(state => state.auth);
    const [displayedImage, setDisplayedImage] = useState(null);
    const [forwardOrNot, setForwardOrNot] = useState(false);
    const [messageToHandle, setMessageToHandle] = useState(null);
    const [showOptionsMenu, setShowOptionsMenu] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editedMessage, setEditedMessage] = useState('');
    const dispatch = useDispatch();
    const [friendsName, setFriendsName] = useState('');
    const [friendId, setFriendsId] = useState('');

    useEffect(() => {
        if (friends) {
            setFriendsName(friends.map(friend => friend.fndInfo.userName));
        }
    }, [friends]);

    useEffect(() => {
        const filteredFriends = friends.filter(friend => friendsName.includes(friend.fndInfo.userName));
        const ids = filteredFriends.map(friend => friend.fndInfo._id);
        setFriendsId(ids);
    }, [friendsName]);

    const handleDisplayedImage = () => {
        if (displayedImage) {
            setDisplayedImage(null);
        }
    };

    const handleCopyMessage = () => {
        if (!messageToHandle || !messageToHandle.message || !messageToHandle.message.text) {
            toast.error(`You can only copy text messages`);
            setShowOptionsMenu(false);
            return;
        }
        const text = messageToHandle.message.text;
        navigator.clipboard.writeText(text);
        toast.success(`Message copied to clipboard`);
        setShowOptionsMenu(false);
    };

    const handleForwardMessage = () => {
        setForwardOrNot(true);
    };

    const handleDeleteMessage = () => {
        if (!messageToHandle || messageToHandle.senderId !== myInfo.id) {
            toast.error(`You can only delete your own messages`);
            setShowOptionsMenu(false);
            return;
        }
        dispatch(deleteMessage(messageToHandle));
        toast.success(`Message deleted successfully`);
        socket.current.emit('delete-message', messageToHandle);
        dispatch({
            type: 'DELETED_MESSAGE',
            payload: messageToHandle
        });
        setShowOptionsMenu(false);
    };

    const handleOptionListClicked = (m) => {
        setShowOptionsMenu(!showOptionsMenu);
        setMessageToHandle(m);
    };

    const handleEditMessage = () => {
        if (!messageToHandle || !messageToHandle.message || !messageToHandle.message.text) {
            toast.error(`You can only edit text messages`);
            setShowOptionsMenu(false);
            return;
        }
        if (messageToHandle.senderId === myInfo.id) {
            setEditMode(true);
            setEditedMessage(messageToHandle.message.text);
            setShowOptionsMenu(false);
        } else {
            toast.error(`You can only edit your own messages`);
        }
    };

    const handleSaveEditedMessage = () => {
        if (editedMessage.trim() === '') {
            toast.error(`Please enter a message`);
            return;
        }
        dispatch(updateMsg(messageToHandle._id, editedMessage));
        const newMessage = { ...messageToHandle, message: { ...messageToHandle.message, text: editedMessage } };
        socket.current.emit('edit-message', newMessage);
        setEditMode(false);
        toast.success(`Message edited successfully`);
    };

    const handleCancelEdit = () => {
        setEditMode(false);
    };

    useEffect(() => {
        if (hideOption) {
            setShowOptionsMenu(false);
        }
    }, [hideOption]);

    const getAudioType = (audioFileName) => {
        if (!audioFileName) {
            console.error("audioFileName is undefined");
            return 'audio/mpeg'; // return a default type or handle it as needed
        }
        const extension = audioFileName.split('.').pop();
        switch (extension) {
            case 'wav':
                return 'audio/wav';
            case 'mp3':
                return 'audio/mpeg';
            default:
                return 'audio/mpeg'; // default to a common type if unknown
        }
    };

    const handleDocumentDownload = (documentName) => {
        // Instead of opening directly, let's fetch it first and create a downloadable link
        fetch(`/documents/${documentName}`)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documentName); // Use document name for download
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(error => console.error('Download failed:', error));
    };

    return (
        <>
            {forwardOrNot ? (
                <Forward
                    currentfriend={currentfriend}
                    myInfo={myInfo}
                    friends={friends}
                    sendMessage={sendMessage}
                    setForwardOrNot={setForwardOrNot}
                    messageToForward={messageToHandle?.message || { text: '', image: '', audio: '', document: '', video: '' }}
                />
            ) : (
                <div className='message-show' onClick={() => handleDisplayedImage()}>
                    <div className='friend_connect'>
                        <img src={`./images/${currentfriend.image}`} alt='' />
                        <h3>{currentfriend.userName} Connect You</h3>
                        <span>{moment(currentfriend.createdAt).startOf('minute').fromNow()}</span>
                    </div>
                    {message && message.length > 0 && message.map((m, index) => {
                        const isSameSender = m.senderId === message[index - 1]?.senderId;
                        const audioType = m.message.audio ? getAudioType(m.message.audio) : null;
                        const imageUrl = m.message.image ? `./images/${m.message.image}` : null;
                        const videoUrl = m.message.video ? `./videos/${m.message.video}` : null; // Video URL

                        return (
                            <div
                                key={index}
                                ref={scrollRef}
                                className={m.senderId === myInfo.id ? 'my-message' : 'fd-message'}
                                style={{ marginTop: index > 0 && m.senderId !== message[index - 1].senderId ? '0px' : '-15px' }}
                            >
                                {!isSameSender && (
                                    <div className='image-message-time'>
                                        <img
                                            src={`./images/${m.senderId === myInfo.id ? myInfo.image : currentfriend.image}`}
                                            alt=''
                                            onClick={() => handleImageClick(m.message.image)}
                                        />
                                    </div>
                                )}
                                <div className='message-time'>
                                    <div className='fd-text' style={{ marginLeft: isSameSender ? '4%' : '0' }}>
                                        {m.message.audio ? (
                                            <audio controls>
                                                <source src={`./audio/${m.message.audio}`} type={audioType} />
                                                Your browser does not support the audio element.
                                            </audio>
                                        ) : m.message.text === '' && imageUrl ? (
                                            <img
                                                src={imageUrl}
                                                alt=''
                                                style={{ backgroundColor: m.senderId === myInfo.id ? 'white' : 'white' }}
                                                onClick={() => setDisplayedImage(m.message.image)}
                                            />
                                        ) : m.message.text === '' && videoUrl ? (
                                            <div className='video-message'>
                                                <video controls width="100%">
                                                    <source src={videoUrl} type="video/mp4" />
                                                    Your browser does not support the video element.
                                                </video>
                                            </div>
                                        ) : m.message.text === '' && m.message.document ? (
                                            <div className='document-message'>
                                                <div className='document-display'>
                                                    <div className='document-icon'>
                                                        <img src={`/images/document-icon.png`} alt='Document Icon' />
                                                    </div>
                                                    <div className='document-info'>
                                                        <p className='document-name'>{m.message.document}</p>
                                                        <p className='document-size'>{/* Display file size here */}</p>
                                                    </div>
                                                    <div className='document-download'>
                                                        <button onClick={() => handleDocumentDownload(m.message.document)}>
                                                            Download
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                className='message-with-icon'
                                                style={{ backgroundColor: m.senderId === myInfo.id ? '#C2DCF1' : '#F0F0F0' }}
                                            >
                                                {editMode && messageToHandle?._id === m._id ? (
                                                    <div className='edit-message'>
                                                        <input
                                                            type='text'
                                                            value={editedMessage}
                                                            onChange={(e) => setEditedMessage(e.target.value)}
                                                        />
                                                        <button onClick={handleSaveEditedMessage}>Save</button>
                                                        <button onClick={handleCancelEdit}>Cancel</button>
                                                    </div>
                                                ) : (
                                                    <div className='message-text'>
                                                        {m.message.text}
                                                        <div className='time'>
                                                            {moment(m.createdAt).calendar(null, {
                                                                sameDay: '[Today] LT',
                                                                lastDay: '[Yesterday] LT',
                                                                lastWeek: 'dddd LT',
                                                                sameElse: 'DD/MM/YYYY h:mm A'
                                                            })}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div className='options-menu-icon' onClick={() => handleOptionListClicked(m)}>
                                            <span>&#8942;</span>
                                        </div>
                                    </div>
                                    {showOptionsMenu && messageToHandle?._id === m._id && (
                                        <div className='options-menu'>
                                            <ul>
                                                <li onClick={handleCopyMessage}>
                                                    <ContentCopyIcon />
                                                    <p>Copy Message</p>
                                                </li>
                                                <li onClick={() => handleForwardMessage()}>                                                    <FontAwesomeIcon icon={faShare} />
                                                    <p>Forward Message</p>
                                                </li>
                                                <li onClick={() => handleEditMessage()}>
                                                    <MdEdit />
                                                    <p>Edit Message</p>
                                                </li>
                                                <li onClick={() => handleDeleteMessage()}>
                                                    <MdDeleteOutline />
                                                    <p>Delete Message</p>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}

                    {displayedImage && (
                        <div className='displayed-image-container'>
                            <img src={`./images/${displayedImage}`} alt='Displayed Image' />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default Message;